.table-space {
    border-collapse: separate;
    border-spacing: 0 2em;
}

.ellipsis-multiline{
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background:#fff;
}
.red-border{
    border: 1px solid red;
}
.err{ font-size: 11px; color: #f00;}
.errBig{ font-size: 14px; color: #f00;}
.login_left_section{ background: #152370;}
.login_title{ font-family: 'Roca one bold'; }
.titlebar{ background: #EBF0FA; color: #397DF8;}
.layout-form{ background: #F7F8FE;}
.review_right_part{ background: #F3F6FC;}
.reviewDocHeight{ min-height: calc(100vh - 176px);}
.signed_doc .state_number{ color: #06B70D;}
.pending_doc .state_number{ color: #8F7EF6;}
.draft_doc .state_number{ color: #397DF8;}
.state_title{ color: #152370;}
.state_absolute p { color: #8090E7;}
.state_absolute span { color: #000;}
.table_row{box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);border-radius: 4px;}
.state_row  .invoice_number_box{box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);}
.table_head{ text-transform: uppercase; font-weight: bold; font-size: 16px; color: #152370;}
.table_data{ color: #4253A9;font-size: 16px;    word-break: break-all;  }
.table_column{ padding: 0 8px;} 
.table_row .table_column{ max-width: 14%; min-width: 16%;}
.table_row .table_column:first-child{ min-width: 20%;}



/* .table_row .table_column:last-child{align-content: center;justify-content: center;display: flex; min-width: 5%;} */
.y_axis_title{ color: #8090E7;font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 12px;}

.rdt_TableHead .rdt_TableHeadRow{background: #F4F7FC;}
.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{    text-transform: uppercase; padding: 0.5rem; color: #152370;    font-family: 'Inter', sans-serif;
    font-weight: 700; font-size: 14px;}
    .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{ padding: 0 1rem; min-width: 120px;}
.rdt_Table .rdt_TableBody .rdt_TableRow{ border: none;}
.rdt_TableBody .rdt_TableRow .rdt_TableCell{padding: 0.5rem;text-align: left;color: #8090E7; font-family: 'Inter', sans-serif;font-weight: 500;font-size: 14px;}
.rdt_TableBody .rdt_TableRow  .rdt_TableCell:last-child{padding: 0 0.5rem 0 0.5rem; min-width:120px}
.rdt_TableBody .rdt_TableRow:nth-child(even){background: #F4F7FC;}
.rdt_TableBody .rdt_TableRow:nth-child(odd){ background: #fff; }
.isDown {transform: rotate(180deg);}

.invoiceList .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{ min-width: 200px;}
.invoiceList .rdt_TableBody .rdt_TableRow:nth-child(even){background: #fff;}
.invoiceList .rdt_TableHead .rdt_TableHeadRow{ border-bottom: none;}
.invoiceList .rdt_Pagination{ border: none;}

.rdt_TableBody .rdt_TableRow:hover:nth-child(even){ background: #ececfb; box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05); }
.rdt_TableBody .rdt_TableRow:hover:nth-child(odd){ background: #ececfb; box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05); }

.rdt_TableBody .rdt_TableRow.active:nth-child(even){ background: #eee2ff; box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05); }
.rdt_TableBody .rdt_TableRow.active:nth-child(odd){ background: #eee2ff; box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05); }


.invoice_list_all .rdt_Table .rdt_TableBody .rdt_TableRow{box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);border-radius: 4px;padding-top: 1.25rem;
    padding-bottom: 1.25rem;    padding-left: 0.5rem;    align-items: flex-start;
    padding-right: 0.5rem; background: #fff;margin-bottom: 1.5rem;}
    .invoice_list_all .rdt_TableBody .rdt_TableRow:hover:nth-child(even){ background: #fff;box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);}
    .invoice_list_all .rdt_TableBody .rdt_TableRow:hover:nth-child(odd){background: #fff;box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);}
    .invoice_list_all .rdt_Table{ background: none;}
    .invoice_list_all .rdt_TableBody .rdt_TableRow .rdt_TableCell{ padding: 0;}
    .invoice_list_all .rdt_TableCell > div:first-child{ white-space: normal; }
    .invoice_list_all .rdt_TableCell > div .table_column{white-space: normal; }
    .invoice_list_all .rdt_TableHead{ display: none;}
    .invoice_list_all .rdt_Table .rdt_TableBody{font-family: 'Inter', sans-serif; font-weight: 400;}
    .invoice_list_all .rdt_TableBody .rdt_TableRow .rdt_TableCell{font-family: 'Inter', sans-serif; font-weight: 400;}

    .data-table-extensions{ margin-bottom: 2rem;}
    .data-table-extensions > .data-table-extensions-filter > .icon {float: left;display: block;width: 20px;height: 24px;background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==);background-repeat: no-repeat;background-position: left center;}
    .data-table-extensions-filter{ display: flex;padding-top: 0.25rem;padding-bottom: 0.25rem;padding-left: 0.5rem;padding-right: 0.5rem; border: 1px solid rgb(209, 213, 219);border-radius: 0.375rem;}
    .data-table-extensions{ display: flex;}
    .filter-text:focus, .filter-text:active, .filter-text:hover{ outline: none !important;}

    .userListTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{ min-width: 280px;} 
    .userListTable .rdt_TableBody .rdt_TableRow:nth-child(even){ background: #fff;box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);}
    .userListTable .rdt_TableBody .rdt_TableRow:nth-child(odd){background: #fff;box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);}
    .userListTable .rdt_TableBody .rdt_TableRow:hover:nth-child(even){ background: #fff;box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);}
    .userListTable .rdt_TableBody .rdt_TableRow:hover:nth-child(odd){background: #fff;box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);}
    .userListTable .rdt_TableBody .rdt_TableRow .rdt_TableCell{ font-weight: 400;}
    .userListTable .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {padding: 1rem 1rem;min-width: 120px; position: relative;}
    .userListTable .rdt_TableHead .rdt_TableHeadRow{background-color:rgb(235, 240, 250)}
    .userListTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{    color: rgba(128, 144, 231,1); font-weight: 400;}
    .invoice_list_all .data-table-extensions{display: inline-flex;position: relative;z-index: 10;transform: translate(0px, -36px);margin: 0;}
    .invoiceList .data-table-extensions{display: inline-flex;position: relative;z-index: 10;transform: translate(0px, -36px);margin: 0;}
    .menudrop_filter{max-height: 300px;overflow: auto;}
    .state_row .state_number { font-size: 1.6vw;}
    .state_row .state_title { font-size: 1vw;}
.label_checkbox {border: 1px solid #ddd;border-radius: 8px;}
.label_checkbox, .label_checkbox span {display: flex;align-items: center;flex-wrap: wrap;}
.label_checkbox {justify-content: space-between;font-family: "Inter",sans-serif;font-weight: 400;font-size: 14px;line-height: 17px;color: #000;}
.label_checkbox strong { display: block; width: 100%;}
.planType_row{ display: flex; width: 100%; max-width: 700px; margin-top: 0px;}
.planType_block{ width: 50%; margin-bottom: 15px;}
.label_checkbox_sub{font-family: "Inter",sans-serif;font-weight: 400;font-size: 14px;line-height: 17px;color: #000;display: flex;align-items: center;flex-wrap: wrap;}
.label_checkbox_sub span{ margin-left: 10px;}
.planType_row_sub{display: flex; width: 100%; max-width: 480px; flex-wrap: wrap; }
.summary_plan_table{ width: 100%; max-width: 600px;} 
.summary_plan_table th{background: #F4F7FC;text-transform: uppercase;padding: 0.5rem;color: #152370;font-family: 'Inter', sans-serif;font-weight: 700;font-size: 14px; text-align: left; width: 200px;}
.summary_plan_table td{padding: 0.5rem;text-align: left;color: #8090E7;font-family: 'Inter', sans-serif;font-weight: 500;font-size: 14px;}
.label_title{ padding: 0.5rem 0;color: #152370;font-family: 'Inter', sans-serif;font-weight: 700;font-size: 16px;}
.label_title_month{padding: 0.5rem 0;color: #152370;font-family: 'Inter', sans-serif;font-weight: 400;font-size: 16px;}
.create_plan_row { box-shadow:  rgba(26, 17, 17, 0.2) 0px 0px 15px -3px; padding: 1rem; border-radius: 10px;}

.rdt_TableCol_Sortable > div{ white-space: normal;}
.highlight_summary{ background: #8090E7;}
.highlight_summary .state_number { color: #fff;}
.biling_field{ width: calc(50% - 0.5rem);}
.biling_edit_section textarea{ resize: none;}

.updatedInput{ border: 1px solid slateblue; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);}

.phash_row{ position: relative;}
.create_phase_row{ display: flex;}
.block_title{ font-size: 18px;color: #152370;font-family: 'Inter', sans-serif;font-weight: 700;}

.chat_module{ width: 50px; height: 50px; position: absolute; bottom: 10px; right: 10px; background: #7335cb url(../assets/images/public/messenger.png) no-repeat center center; background-size: 30px auto; border-radius: 50%; box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); } 

.pricing_row{ flex-direction: column; display: flex;}

.custom_popup{ position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 99;}
.custom_popup:before{ position: absolute; content: ''; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0, 0, 0, 0.3);}
.layout_popup{ position: absolute; top: 50%; transform: translate(0%,-50%); left: 0; right: 0; max-width: 400px; background: #fff; margin: auto; }
.title_popup{ color: #fff; margin-bottom: 10px; padding: 10px; background: #50258e; font-weight: bold; text-transform: uppercase;}
.popup_body{padding: 20px;}
.form_row_popup{ margin-bottom: 20px;}
.popup_close_button{ width: 20px; height: 20px; position: absolute; top: 10px; right: 10px; background: url(../assets/images/public/close.svg) no-repeat 0 0; background-size: contain;}

.isDisabled{ opacity: 0.6;}


.userListTable .rdt_TableCell > div:first-child{ white-space: normal; }
.userListTable .rdt_TableCell > div .table_column{white-space: normal; }
.invoiceactyivityList .rdt_TableRow .rdt_TableCell > div {white-space: normal; }

.goog-logo-link{ display: none;}
.skiptranslate{ display: none;}

    .skiptranslate.goog-te-gadget{ display: block;}
    body{ top: 0 !important;}
    html{ height: auto !important;}
    #google_translate_element span{ display: none;}
    .skiptranslate.goog-te-gadget{ height: 25px; overflow: hidden;}
    #google_translate_element{ display: flex; align-items: center;}

.client_spends_row{ display: flex; justify-content: flex-start; align-items: center; border: 1px solid rgb(209, 213, 219); border-radius: 5px; position: relative;}
.title_spends_row{ display: flex; justify-content: flex-start; align-items: center;}

.removeRow{ min-width: 6%; padding: 5px; position: relative;}
.removeRow:after{ width: 24px; height: 24px; position: absolute; top: 50%; left: 0; right: 0; margin: auto; transform: translate(0,-50%); border-radius: 50%; border: 1px solid rgb(255, 103, 80); content: '';}
.removeRow:before{ width: 10px; height: 2px; position: absolute; top: 50%; left: 0; right: 0; margin: auto; transform: translate(0,-50%); border-radius: 50%; border: 1px solid rgb(255, 103, 80); content: '';}
.title_spends_row .removeRow:after{ display: none; }
.title_spends_row .removeRow:before{ display: none; }
.input_table_row{ padding: 5px; min-width: 23.5%; font-size: 14px;}
.details_inner_table{ width: 100%;}
.details_inner_table td{ padding: 10px;}
.rdt_TableBody .rdt_TableRow .rdt_TableCell { align-items: flex-start;}
.rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child{align-items: center;}
.rdt_TableBody .rdt_TableRow .rdt_TableCell div:first-child{ white-space: normal;}

.customplatform_row .input_table_row{padding: 5px;min-width: 18%;font-size: 13px;max-width: 18%;}
.customplatform_row .input_table_row:last-child{max-width: 22%;min-width: 22%;}
.tag_client_agreement{  border-radius: 50%; width: 24px; height: 24px; display: flex; align-items: center; justify-content: center; position: absolute; right: 0.7rem; top: 50%; transform: translate(0,-50%); background:#50258e ; color: #fff; font-size: 12px;}

